<template>
  <div class="leftbig">
    <div class="leftTop">高考动态</div>
    <div class="menu">
      <el-menu
        style="border-right: 0px"
        :default-active="active"
        @select="handleSelect"
      >
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">找学校</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-menu"></i>
          <span slot="title">找专业</span>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="el-icon-menu"></i>
          <span slot="title">分数查询</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="applet">
      <img src="@/assets/img/appletCode.png" alt="小程序" />
    </div>
    <div class="oneToOne" @click="goOneToOne">
      <img src="@/assets/img/oneToOne.png" alt="1对1服务" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menulist: [], //左侧菜单
      active: "1", //激活项
    };
  },
  mounted() {
    this.$emit("gettype", 1);
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    handleSelect(key, keyPath) {
      console.log("123");
      console.log(key);
      this.$emit("getsubjectId", key);
      this.$emit("gettype", key);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff;
  color: #1ab394 !important;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../../../assets/img/leftmune_top_bg.jpg) center top
      no-repeat;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
  .applet {
      width: 100%;
      img {
        width: 160px;
        margin-left: 50px;
      }
    }
}
</style>
