<template>
  <div>
    <div class="scrollChart">
      <ScrollChart :scrImg="scrImg"></ScrollChart>
    </div>
    <div class="contentbotton">
      <div class="left">
        <menuLeft @gettype="handleSelect" :formdata="formdata"></menuLeft>
        <!-- <div class="leftbig">
          <div class="leftTop">数据查询</div>
          <div class="menu">
            <el-menu
              style="border-right: 0px"
              :default-active="
                '/' +
                $route.path.split('/')[1] +
                '/' +
                $route.path.split('/')[2]
              "
              @select="handleSelect"
            >
              <el-menu-item index="/dataQuery/findschool">
                <i class="el-icon-menu"></i>
                <span slot="title">找学校</span>
              </el-menu-item>
              <el-menu-item index="/dataQuery/findProfessional">
                <i class="el-icon-menu"></i>
                <span slot="title">找专业</span>
              </el-menu-item>
              <el-menu-item index="/dataQuery/scoresQuery">
                <i class="el-icon-menu"></i>
                <span slot="title">分数查询</span>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="applet">
            <img :src="QRcode[0]" :onerror="smallImgError" alt="小程序" />
          </div>
          <div class="oneToOne" @click="goOneToOne">
            <img src="@/assets/img/oneToOne.png" alt="1对1服务" />
          </div>
        </div> -->
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { getAdvertisementDetail } from "@/api";
import menuLeft from "@/components/menu";
import ScrollChart from "@/components/scrollChart";
import leftmenu from "./child/components/leftmunu";
export default {
  name: "dataQuery",
  components: {
    leftmenu,
    menuLeft,
    ScrollChart,
  },
  data() {
    return {
      active: "1",
      scrImg: [], //轮播图
      QRcode: [],
      formdata: {
        topText: "数据查询",
        menulist: [
          { path: "/dataQuery/findschool", title: "找学校" },
          { path: "/dataQuery/findProfessional", title: "找专业" },
          { path: "/dataQuery/scoresQuery", title: "分数查询" },
        ],
      },
    };
  },
  mounted() {
    this.getTop();
    this.getQRcode();
  },
  methods: {
    goOneToOne(){
      this.$router.push("/volunterservce/oneServe");
    },
    // 顶部轮播图片
    async getTop() {
      let res = await getAdvertisementDetail({ id: 4 });
      if (res.code == 1) {
        this.scrImg = res.data.images;
      }
      function getimgsrc(html) {
        var reg = /<img.+?src=('|")?([^'"]+)('|")?(?:\s+|>)/gim;
        var arr = [];
        let tem = [];
        while ((tem = reg.exec(html))) {
          arr.push(tem[2]);
        }
        console.log(arr);
        return arr;
      }
    },
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images;
      }
    },
    handleSelect(key) {
      if (key == "/dataQuery/findschool") {
        this.$router.push("/dataQuery/findschool");
        // 讲座管理
      }
      if (key == "/dataQuery/findProfessional") {
        // 找专业
        this.$router.push("/dataQuery/findProfessional");
      }
      if (key == "/dataQuery/scoresQuery") {
        // 分数查询
        let sessionId = sessionStorage.getItem("sessionId") || null;
        if (sessionId) {
          this.$router.push("/dataQuery/scoresQuery");
        } else {
          this.$message({
            showClose: true,
            message: "请先登录",
            type: "error",
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.oneToOne:hover{
  img{
    transform: scale(1.2);
  }
}
.scrollChart {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.leftbig {
  width: 260px;
  .leftTop {
    background: url(../../assets/img/leftmune_top_bg.jpg) center top no-repeat;
    background-position: 100% auto;
    height: 106px;
    text-align: center;
    line-height: 106px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
  }
  .menu {
    width: 100%;
  }
  .applet {
    width: 100%;
    img {
        width: 160px;
        margin-left: 50px;
      }
  }
}
.right {
  width: 960px;
  box-sizing: border-box;
  padding-left: 30px;
  .title {
    width: 960px;
    height: 106px;
    font-size: 26px;
    color: #222222;
    line-height: 106px;
    text-align: center;
  }
  .rightcontent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    .videodiv {
      width: 220px;
      margin: 25px 40px;
      height: 170px;
      background-color: #e6e6e6;
    }
  }
}
/deep/.el-menu-item {
  font-size: 16px !important;
}
/deep/.el-menu-item.is-active {
  color: #1ab394 !important;
}
/deep/.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  background-color: #fff;
  color: #1ab394 !important;
}
.scrollChart {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.contentbotton {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
.qrCode {
  width: 120px;
  height: 120px;
}
</style>